import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import AulaAoVivo from '../components/AulaAoVivo'
import Footer from '../components/Footer'

import { 
  header,
  bg5,
} from '../components/styles/InternalPages.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Vamos falar sobre as verdades de uma redação nota 1000?" />
    <NavBar location={props.location} />
    <div css={header}>
      <div css={bg5} />
      <div id='aplicacao' />
      <AulaAoVivo />
    </div>
    <Footer />
  </Fragment>
)

export default StudentsPage
