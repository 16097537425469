import React, { Fragment } from 'react'

import {
  inspired,
  container,
  contentView,
  columns, 
  column,
  visionBox,
  subscription,
  formMt,
  pFont,
  contenInfo
} from './styles/EstudeAqui.styles'

import {
  btn3,
  btn5,
} from '../components/styles/InternalPages.styles'

const InspiredSite = props => (
  <Fragment>
    <section>
      <div css={inspired}>
        <div css={container}>
          <div css={visionBox}>
            <div css={columns} style={{ alignItems: 'flex-start' }}>
              <div css={column}>
                <div css={[contentView, pFont]}>
                  <h3>Vamos falar sobre as verdades de uma redação nota 1000?</h3>
                  <p style={{ marginBottom: 22 }}>O que é realmente uma redação nota 1000 no ENEM? Como posso me preparar para alcançar a nota máxima com minha produção textual?</p>
                  <p style={{ marginBottom: 22 }}>O <b>Professor Carlos André</b>, um dos melhores professores de redação do Brasil, tem estas respostas, e vai ensinar você nesta terça-feira (29/09) às 18h!</p>
                  <p>Além de dicas valiosas e exemplos práticos, discutiremos o papel do livro 1984, de George Orwell, como repertório cultural e lançaremos o concurso de redação baseado na obra e promovido em parceria com a <a href='https://olimpiadapocket.com.br/olimpiada-pocket-1984' target='_blank' rel='noopener noreferrer'>Olimpíada Pocket</a> e o <a href='https://www.institutovertere.com.br/' target='_blank' rel='noopener noreferrer'>Instituto Vertere</a>.</p>
                </div>
              </div>
              <div css={column}>
                <div css={[subscription, formMt]}>
                  <h4>FAÇA SUA INSCRIÇÃO</h4>
                  <h5>* Campos obrigatórios</h5>
                  {/* <iframe src='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' /> */}
                  <form method="post" action="olimpiada-pocket-1984" data-netlify="true" name="aulacarlosandre">
                  <input type="hidden" name="form-name" value="aulacarlosandre" />
                  <label>
                      Nome Completo: *
                      <input type="text" name="name" id="name" placeholder='Coloque aqui seu nome completo' />
                    </label>
                    <label>
                      E-mail: *
                      <input type="email" name="email" id="email" placeholder='Coloque aqui seu email' />
                    </label>
                    <label>
                      Celular: *
                      <input type="text" name="telefone" id="telefone" placeholder='DDD + Número' />
                    </label>
                    <label>
                      Série do Aluno: *
                      <select name="serie" id="serie">
                        <option value="">Selecione</option>                  
                        <option value="Antes do 9º ano">Antes do 9º ano</option>
                        <option value="9º ano E.F">9º ano E.F</option>
                        <option value="1ª série E.M">1ª série E.M</option>
                        <option value="2ª série E.M">2ª série E.M</option>
                        <option value="3ª série E.M">3ª série E.M</option>
                        <option value="Ensino médio completo">Ensino médio completo</option>
                      </select>
                    </label>
                    <label>
                      Colégio do Aluno: *: *
                      <input type="text" name="colegio" id="colegio" placeholder='Coloque aqui o nome do seu colégio' />
                    </label>
                    <label>
                      7+3=? : *
                      <input type="text" name="soma" id="soma" placeholder='Coloque aqui o resultado' />
                    </label>
                    <button css={btn3} type="submit">INSCREVA-SE</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section style={{ background: '#00078C', marginTop: 80 }}>
      <div css={container}>
        <div css={columns}>
          <div css={[column, contenInfo]}>
            <p>A Olimpíada Pocket convida todos os apaixonados por essa obra marcante, seja por seu aspecto literário, ou por sua força como metáfora política ou social, a aprenderem um pouco mais sobre estilo da escrita orwelliana, panorama histórico da criação da obra, problemáticas políticas históricas e atuais levantadas por ela, inserção do romance na tradição distópica e todos os possíveis matizes de interpretação e recepção deste clássico. </p>
            <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' target='_blank' rel='noopener noreferrer' css={btn5}>INSCREVA-SE NA POCKET!</a>
          </div>
        </div> 
      </div>         
    </section>
  </Fragment>
)

export default InspiredSite
