import React, { Fragment } from 'react'
import Vertere from '../images/partners/institutovertere.svg'
import Sapientia from '../images/partners/sapientia.svg'
import Obecon from '../images/partners/obecon.svg'
import Obling from '../images/partners/obling.svg'
import Vitalis from '../images/partners/vitalis.svg'
import Celeritas from '../images/partners/celeritas.svg'
import {
  inspired,
  container,
  contentView,
  columns, 
  column,
  visionBox,
  title,
} from './styles/Footerr.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired} style={{ marginTop: 60 }}>
      <div css={container}>
        {/* <div css={title}>PARCEIROS</div>
        <div css={subTitle} style={{ marginBottom: 16 }}>Realização</div> */}
        <div css={visionBox}>
          <div css={columns}>
            <div css={column}>
              <div style={{ flexDirection: 'column', marginBottom: 32 }}>
                <div css={title} style={{ marginBottom: 20 }}>Realização</div>
                <div css={contentView}>
                  <a href='http://institutovertere.com.br' target='_blank' rel='noopener noreferrer' title='Instituto Vertere'>
                    <img src={Vertere} alt='' />
                  </a>
                </div>
                <div css={title} style={{ marginTop: 24, marginBottom: 20 }}>Instituto Vertere</div>
                <p>O Instituto Vertere é uma entidade sem fins lucrativos que promove o acesso a olimpíadas do conhecimento. Há mais de 20 anos, nossa equipe trabalha na criação e execução das principais provas olímpicas do Brasil, como Astronomia, Biologia, Economia, Física, Linguística e Medicina. Também investimos em tecnologias que buscam democratizar o acesso às olimpíadas e ajudar os alunos na preparação para provas nacionais e internacionais.</p>
              </div>
            </div>
            <div css={column}>
              <div style={{ flexDirection: 'column' }}>
                <div css={title} style={{ marginBottom: 20 }}>Conheça nossas outras Olimpíadas</div>
                <div css={contentView}>
                  <a href='https://olimpiadadofuturo.com.br/' target='_blank' rel='noopener noreferrer' title='Sapientia – Olimpíada do Futuro: Transforme-se!'>
                    <img src={Sapientia} alt='' />
                  </a>
                  <a href='https://obecon.org/' target='_blank' rel='noopener noreferrer' title='Olimpíada Brasileira de Economia'>
                    <img src={Obecon} alt='' />
                  </a>
                  <a href='http://www.obling.org/' target='_blank' rel='noopener noreferrer' title='Olimpíada Brasileira de Linguística'>
                    <img src={Obling} alt='' />
                  </a>
                  <a href='https://olimpiadademedicina.com.br/' target='_blank' rel='noopener noreferrer' title='Vitalis - OLIMPÍADA DE MEDICINA'>
                    <img src={Vitalis} alt='' />
                  </a>
                  <a href='https://olimpiadademedicina.com.br/' target='_blank' rel='noopener noreferrer' title='Vitalis - OLIMPÍADA DE MEDICINA'>
                    <img src={Celeritas} alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default InspiredSite
